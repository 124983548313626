import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/home/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "orcaHome" */ '../views/home/index.vue')
  },
  {
    path: '/price',
    name: 'price',
    component: () => import(/* webpackChunkName: "orcaHome" */ '../views/price/index.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import(/* webpackChunkName: "orcaHome" */ '../views/aboutUs/index.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/helpCenter/index.vue')
  },
  {
    path: '/region-guide-ios',
    name: 'regionGuideIos',
    component: () => import(/* webpackChunkName: "regionGuideIos" */ '../views/regionGuideIos/index.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "download" */ '../views/download/index.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register/index.vue')
  },
  {
    path: '/myAccount',
    name: 'MyAccount',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/myAccount/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "orcaLogin" */ '../views/login/index.vue')
  },
  {
    path: '/bindEmail',
    name: 'BindEmail',
    component: () => import(/* webpackChunkName: "bindEmail" */ '../views/bindEmail/index.vue')
  },
  {
    path: '/forgetPwd',
    name: 'ForgetPwd',
    component: () => import(/* webpackChunkName: "forgetPwd" */ '../views/forgetPwd/index.vue')
  },
  {
    path: '/resetPwd',
    name: 'ResetPwd',
    component: () => import(/* webpackChunkName: "resetPwd" */ '../views/resetPwd/index.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/privacyPolicy/index.vue')
  },
  {
    path: '/terms-of-service',
    name: 'TermsService',
    component: () => import(/* webpackChunkName: "termsService" */ '../views/termsService/index.vue')
  },
  {
    path: '/pay-success',
    name: 'paySuccess',
    component: () => import(/* webpackChunkName: "termsService" */ '../views/paySuccess/index.vue')
  },
  {
    path: '/pay-cancel',
    name: 'payCancel',
    component: () => import(/* webpackChunkName: "termsService" */ '../views/payCancel/index.vue')
  },
  {
    path: '/ad2',
    name: 'cn_ad2',
    component: () => import(/* webpackChunkName: "cn_ad2" */ '../views/ad/cn_index2.vue')
  },
  {
    path: '/admkx',
    name: 'admkx',
    component: () => import(/* webpackChunkName: "admkx" */ '../views/admk/aindex')
  },
  {
    path: '/admk',
    name: 'admk',
    component: () => import(/* webpackChunkName: "admk" */ '../views/admk/bindex')
  },
  {
    path: '/adnkx',
    name: 'adnkx',
    component: () => import(/* webpackChunkName: "adnkx" */ '../views/adnk/aindex')
  },
  {
    path: '/adnk',
    name: 'adnk',
    component: () => import(/* webpackChunkName: "adnk" */ '../views/adnk/bindex')
  }

]


// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})

let isNavigationHandled = false;

router.beforeEach((to, from, next) => {
  if (isNavigationHandled) {
    // If navigation has already been handled, skip this guard
    next();
    return;
  }
  // Check the current domain and modify the route path accordingly
  const currentUrl = window.location.href;

  if (currentUrl === 'https://mk.gglkxr.buzz/') {
    isNavigationHandled = true;
    next('/admkx' + to.fullPath);
  } else if (currentUrl === 'https://nk.gglkxr.buzz/') {
    isNavigationHandled = true;
    next('/adnkx' + to.fullPath);
  } else if (currentUrl === 'http://localhost:8089/') {
    isNavigationHandled = true;
    next('/plan' + to.fullPath);
  } else {
    // Continue with the original path
    next();
  }
});

export default router


